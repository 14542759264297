.cardsSection {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .card {
    border: 1px solid black;
    border-radius: 4px;
     cursor: pointer;
  }
  .cardSelected {
    border: 1px solid #f87171;
  }

  .cardTitle {
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: #000000;
    margin-top: 0px;
  }
  .cardTitleSelected {
    color: #f87171;
  }

  .cardBody {
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 18px !important;
  }

  .videoContent {
     height: 50vh;
  }
}
