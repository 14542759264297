@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.aiToolRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  padding: 6rem;
  .title {
    font-family: "Inter", sans-serif;
    font-size: 2.75rem;
    font-weight: 800;
    color: #353535;
    margin-bottom: 0.5rem;
  }

  .titleDesc {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 4rem;
    text-align: center;
    line-height: 25px;
  }

  .boldClass {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 25px;
  }

  .buttonSpace {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }

  .tabButton {
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: rgba(0, 0, 0, 0.75) !important;
    border: 1px solid transparent;
    border-radius: 999em 999em 999em 999em;
    padding: 8px 20px !important;
    cursor: pointer;
    display: flex ;
    align-items: center;
  }

  .selectedtab {
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #f87171;
    border: 1px solid #f87171;
    border-radius: 999em 999em 999em 999em;
    padding: 8px 20px !important;
    display: flex;
    align-items: center;
  }

  .tabContent {
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-bottom: 2.5rem;
    // flex-wrap: wrap;
    // flex-grow: 1;
  }
}

@include breakpoint(tablet) {
  .aiToolRoot {
    padding-left: 1rem;
    padding-right: 1rem;
    .tabContent {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr) !important;
    }
    .title {
      font-size: 34px !important;
    }
    .titleDesc {
      font-size: 14px !important;
      span {
        font-size: 14px !important;
      }
    }
    .tabButton {
      font-size: 7.94px !important;
      line-height: 10px !important;
    }
    .selectedtab {
      font-size: 7.94px !important;
      line-height: 10px !important;
     
    }
    .buttonSpace {
      width: 93% !important;
    }
  }
}
