@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto:wght@300&display=swap");
@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.howToWorkRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 76%;

  .title {
    font-family: "Inter", sans-serif;
    font-size: 2.5rem;
    font-weight: 800;
    color: #000000;
    margin-bottom: 0.5rem;
  }

  .titleDesc {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5rem;
    text-align: center;
    line-height: 23px;
  }

  .bodyContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 450px;
    gap: 15px;
  }

  .cardsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    font-family: "Inter", sans-serif;
    padding-left: 10px;
    padding-right: 10px;

    .card {
      border: 1px solid black;
      border-radius: 10px;
      // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    .cardSelected {
      border: 1px solid #f87171;
    }

    .cardTitle {
      font-family: "Inter", sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      color: #000000;
      margin-top: 0px;
    }
    .cardTitleSelected {
      color: #f87171;
    }

    .cardBody {
      font-family: "Inter", sans-serif;
      font-size: 0.9rem;
      color: #000000;
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 18px !important;
    }
  }

  .videoContent {
    width: 70%;
    border-radius: 20px;

    .video {
      border-radius: 20px;
      overflow: hidden;
    }
  }

  //   .video {
  //     width: 90%;
  //     height: 90%;
  //     border-radius: 10px;
  //     margin: 0px;
  //     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  //   }
}

.bodyContentMobile {
  display: none;
}

@include breakpoint(tablet) {
  .howToWorkRoot {
    .title {
      font-size: 34px !important;
    }
    .titleDesc {
      font-size: 12px !important;
      margin-bottom: 2rem !important;
    }

    .bodyContent {
      display: none !important;
    }
  }
  .bodyContentMobile {
    display: block !important;
  }
}
