@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.brand_container {
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  .brand_text {
    font-size: 25px;
    font-family: "Inter", sans-serif;
    // font-size: 15px !important;
  }

  .brand_subtext {
    font-size: 22px;
    font-family: "Inter", sans-serif;
  }
  .brand_listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    // display: flex;
    // justify-content: space-evenly;
    margin-top: 3rem;

    .brand_img {
      width: 70px;
      height: 27px;
    }
  }
}

@include breakpoint(tablet) {
  .brand_container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .brand_text {
      font-size: 22px !important;
      text-align: center;
    }
    .brand_listing {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
