@import "./mixin.scss";
@import "./variable.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: $roboto;
}
.linkText {
  @include link-text;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.color-primary {
  color: $primary;
}
.color-secondary {
  color: $deepBlue;
}

.f-500 {
  font-weight: 500;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin: 0 auto;
}
.my-auto {
  margin: auto 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 24px;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 24px;
}

.px-1 {
  padding: 0 4px;
}
.px-2 {
  padding: 0 8px;
}
.px-3 {
  padding: 0 12px;
}
.px-4 {
  padding: 0 24px;
}

// .py-1{
//   padding: 0 4px ;
// }
// .py-2{
//   padding: 0 8px ;
// }
// .py-3{
//   padding: 0 12px ;
// }
// .py-4{
//   padding: 0 24px ;
// }

.card-selected {
  border: 1px solid $deepBlue !important;
}
.display-none {
  display: none;
}
.display-hidden {
  visibility: hidden;
  pointer-events: none;
}
.text-center {
  text-align: center;
}

.content_spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.d-flex {
  display: flex;
}
.flexAlign-center {
  display: flex;
  align-items: center;
  // margin-top: 5rem;
  width: 62%;
}
.container-block {
  // padding: 25px;
  padding: 50px 25px 25px;
}
.flexBox {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}
.ant-spin-spinning {
  color: black;
  margin-top: 10%;
}
.w-100 {
  width: 100%;
}
.icon-space {
  padding: 1rem;
  cursor: pointer;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.font-size-m {
  font-size: 12px;
}
.font-size-xl {
}

.text_button {
  font-family: $roboto;
  font-size: 14px;
  font-weight: 600;

  span:hover {
    border-bottom: 1px solid $primary;
  }
}

.title {
  font-family: "Inter", sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  color: #000000;
  margin-bottom: 0.5rem;
}

.small_text {
  font-family: "Inter", sans-serif;
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #353535 !important;
}

.paragraph_text {
  font-family: "Inter", sans-serif;
  font-size: 17px ;
  font-weight: 500 ;
  color: #353535 ;
}

.address_text{
  font-family: "Inter", sans-serif;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #353535 !important;
}

.bottom-shade-line {
  background: linear-gradient(90deg, #de5954, rgba(253, 142, 41, 0))
    bottom;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  // border-image: 1px solid linear-gradient(to bottom right, #f87171, rgba(248, 113, 113, 0)) ;
}
.bottom-shade-line-mobile {
  background: linear-gradient(90deg, #de5954, rgba(253, 142, 41, 0))
    bottom;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  width: 40%;
  padding: 1rem 0;
  // border-image: 1px solid linear-gradient(to bottom right, #f87171, rgba(248, 113, 113, 0)) ;
}


.ant-btn-default {
  border-color: transparent !important;
  box-shadow: none !important;
}

.center-x {
  display: flex;
  justify-content: center;
}

.font-weight-6{
  font-weight: 600 !important;
}

.border-c {
  border-color: #F87171  !important;
}