@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

header {
  position: sticky;
  top: 0;
  background: $white;
  z-index: 1000;
  .header_container {
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);

    .header_content {
      display: flex;
      gap: 20px;
      align-items: center;

      .try_btn {
        background: $black;
        color: $white;
        // font-size: 12px;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 700;
      }

      .sandwitch_icon {
        display: none;
      }

      .ant-btn-text:not(:disabled):hover {
        background: transparent !important;
      }
      .ant-btn-default:hover {
        border-color: transparent;
      }
      .mobile_view {
        display: none;
      }
    }
  }

  @include breakpoint(tablet) {
    .header_subContent {
      display: none;
    }
    .header_content{
      .try_btn {
        font-size: 10px !important;
      }
    }
    .sandwitch_icon {
      display: block !important;
      cursor: pointer;
    }
    .mobile_view {
      height: 20vh;
      @include flex-column-center;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    }
    .header_logo {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .header_icon {
        width: 150px;
      }
    }
    .ant-btn-text:not(:disabled):hover {
      background: transparent !important;
    }
  }
}
