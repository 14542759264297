@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto:wght@300&display=swap");
@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.before-after-slider {
  //width: 31%;
  height: 350px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  background: linear-gradient(
    to top right,
    rgba(248, 113, 113, 1),
    rgba(248, 113, 113, 0)
  );
  border-radius: 10px;
  padding: 1px;
  margin: 1rem;

  .module {
    background: white;
    border-radius: 10px;
    height: 100%;
    padding: 1rem;
    padding-bottom: 0.5rem;
  }

  .compareImage {
    width: 100%;
    height: 60%;
    border-radius: 10px;
  }

  .cardTitle {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
    color: #353535;
    margin-top: 10px;
    line-height: 28px;
  }

  .cardBody {
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 22px !important;
  }
}

@include breakpoint(tablet) {
  .before-after-slider {
    height: fit-content !important;
    .cardTitle {
      font-size: 0.875rem !important;
    }
    .cardBody {
      font-size: 0.755rem !important;
    }
  }
}
