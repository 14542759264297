@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
.ant-modal-content {
  padding: 0 !important;
}

.ant-select-item-option-content,
.ant-select-selection-item {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}
.ant-modal {
  top: 85px;
}
.modal_inner_content {
  display: flex;
}
.resp_height {
  height: calc(100vh - 95px) !important;
  border-radius: 0 !important;
}
.form_title {
  margin-bottom: 5px !important;
}
.form_description {
  color: #606060;

}
.form {
  padding: 50px;
  height: 50%;
  margin: auto;
}
.model_img_form {
  height: 80vh;
  object-fit: contain;
  border-radius: 8px 0 0 8px;
}
.form_label {
  span {
    color: #ef2323;
  }
}
.form_inputs {
  width: 100%;
  height: 40px;
}
.ant-input-wrapper,
.ant-input-number-input-wrap,
.ant-select-selection-search {
  width: 100%;
  height: 40px;
  input {
    width: 100%;
    height: 40px;
  }
}
.input_space {
  width: 100%;
  height: 80px;
  text-align: left;
}
.form-btns {
  width: 100%;
  height: 40px;
  margin-top: 25px;
}
.form-btn-1 {
  background-color: $primary;
  color: white;
}
.form-btn-1:hover {
  color: white !important;
}
.form-btn-2 {
  border-color: $primary;
  color: $primary;
}
.form-btn-2:hover {
  // border-color: $primary;
  color: $primary !important;
}
.error_class {
  color: #ff3030;
  padding-left: 5px;
  font-size: 10px;
  // margin-top: 2px;
}
.thankyou_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 20px;
}
.country_select,
.ant-input-number-input {
  width: 100px;
  height: 40px !important;
  .ant-select-selector {
    height: 40px !important;
    align-items: center;
  }
}
.ant-select-selection-search-input {
  height: 40px !important;
}
// .flag_option{

// }

@media screen and (max-width: 2048px) and (min-width: 1700px) {
  .model_img_form {
    height: 66vh !important;
  }
  .resp_height {
    height: calc(100vh - 95px) !important;
    border-radius: 0 !important;
  }
}
@media screen and (max-width: 1400px) {
  .model_img_form {
    height: 90vh !important;
  }
  .resp_height {
    height: calc(100vh - 95px) !important;
    border-radius: 0 !important;
  }
  // .modal_inner_content .input_space{
  //     height: 80px;
  // }

  // .ant-input-wrapper, .ant-input-number-input-wrap{
  //     width: 100%;
  //     height: 30px !important ;
  //     input{
  //         width: 100%;
  //     height: 30px !important;
  //     }
  // }
  .form_inputs,
  .ant-input-number-input-wrap,
  .ant-input-number-input,
  .ant-input-number-group-addon,
  .form-btns {
    width: 100%;
    height: 35px !important;
  }
  .ant-modal {
    top: 32px !important;
  }
  .input_space {
    height: 75px !important;
  }
  .country_select,
  .ant-select-selection-search-input {
    width: 100px;
    height: 35px !important;
    .ant-select-selector {
      height: 35px !important;
      align-items: center;
    }
  }
}
@media screen and (max-width: 992px) {
  .model_img_form {
    display: none;
  }
  .form {
    padding: 25px !important;
  }
  .form_description {
    font-size: 0.8rem;
  }
  .form_inputs,
  .ant-input-number-input-wrap,
  .ant-input-number-input,
  .ant-input-number-group-addon,
  .form-btns {
    width: 100%;
    height: 45px !important;
  }
  .country_select,
  .ant-select-selection-search-input {
    width: 100px;
    height: 40px !important;
    .ant-select-selector {
      height: 40px !important;
      align-items: center;
    }
  }
  .thankyou_page {
    padding: 50px;
  }
  .form-btns {
    margin-top: 0 !important;
  }
  .form-btn-1 {
    margin-top: 10px !important;
  }
}
