@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.footer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 80%;
  padding-top: 6rem;

  .contact_part {
    display: flex !important;
    background: #de5954;
    padding: 4rem;
    justify-content: space-between;
    // padding-bottom: 6rem;
    width: 100%;
    .contact_title {
      width: 44%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title_legend {
        font-family: "Inter", sans-serif;
        font-size: 2rem;
        font-weight: 800;
        color: #ffffff;
        margin-bottom: 0.5rem;
      }
    }
    .contact_email {
      width: 42%;

      .compact_button {
        input {
          padding: 10px;
          background: $primary;
          width: 60%;
          border: 1px solid $white;
        }
        ::placeholder {
          color: $white;
        }
        .expert_btn {
          cursor: pointer;
          padding: 10px;
          /* border-color: white; */
          width: 30%;
          background: #b8433f;
          border: 1px solid $white;
          color: $white;
        }
      }
    }
  }
  .address_part {
    display: flex;
    padding-top: 6rem;
    padding-bottom: 3.5rem;
    justify-content: space-between;

    .company_content {
      display: flex;
      gap: 17px;
      flex-direction: column;
      width: 30%;

      .content_legend {
        width: 68%;
      }

      .email_id {
        display: flex;
        gap: 10px;
        padding: 10px 0;
      }
    }
  }
}

.mobile_copyright {
  display: none !important;
}

@include breakpoint(tablet) {
  
  .footer_container {
    width: 100% !important;
    .contact_part {
      flex-direction: column;
      padding: 2rem !important;
      height: fit-content !important;
      .contact_title {
        width: 100% !important;

        .title_legend {
          font-size: 22px !important;
        }
      }
      .contact_email {
        width: 100% !important;
      }
      .compact_button {
        display: flex;
        gap: 10px;
        flex-direction: column;

        input {
          width: 100% !important;
        }
        .expert_btn {
          width: 100% !important;
        }
      }
    }
    .company_addressOne {
      margin-top: 2rem !important;
    }
    .address_part {
      flex-direction: column;
      padding-left: 2rem !important;
      padding-right: 2rem !important;

      .company_content {
        width: 100% !important;
        .content_legend {
          width: 100% !important;
        }
      }
      .corporate_Address {
        margin-top: 2rem;
      }
      .mobile_copyright {
        display: flex !important;
      }
    }
  }
}
