@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.banner_container {
  padding: 10px 7rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  margin-top: 5rem;

  .banner_text {
    width: 82%;
    text-align: center;

    h2 {
      font-family: "Inter", sans-serif;
      font-size: 4.25rem;
      font-weight: 800;
      color: #000000;
      .sub_text {
        font-family: "Inter", sans-serif;
        font-size: 4.25rem;
        font-weight: 800;
        color: #000000;
        color: $primary;
      }
    }
  }
  .banner_subText {
    font-size: 1.375rem;
    width: 50rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 600;
    span {
      b {
        font-family: "Inter", sans-serif;
        font-size: 22px;
        font-weight: 700;
      }
    }
  }

  .content_spacing {
    .ant-btn-default:hover {
      border-color: transparent;
    }

    .credit_text {
      font-size: 16px;
      color: $grey;
      text-align: center;
    }

    .free_btn {
      background: $primary;
      color: $white;
      width: 14rem;
      height: 50px;
      font-family: "Inter", sans-serif;
      font-weight: 800;
      font-size: 20px;
    }
  }

  .banner_video {
    height: 507px;
    width: 901px;
    // position: relative;
    //   padding-top: 56./25%; /* 720 / 1280 = 0.5625 */
    // border-radius: 5px;
    margin-top: 2rem;
    object-fit: contain;

    .react-player {
      //   position: absolute;
      // top: 0;
      // left: 0;
      border-radius: 20px;
      overflow: hidden;
    }
  }
}

@include breakpoint(tablet) {
  .banner_container {
    padding: 10px 1rem !important ;
    .banner_text {
      width: 100% !important;

      h2 {
        font-size: 2.25rem !important ;
      }

      .sub_text {
        font-size: 2.25rem !important;
      }
    }
    .content_spacing {
      width: 100% !important;
      .free_btn {
        font-size: 12px !important;
        height: 40px !important;
        width: 100% !important;
      }
    }
    .banner_subText {
      font-size: 0.875rem !important;
      width: 20rem !important;

      span {
        b {
          font-size: 0.87rem !important;
        }
      }
    }
    .sub_text {
      display: block;
      width: 100%;
    }

    .banner_video {
      height: 372px;
      width: 87%;
    }
  }
}
