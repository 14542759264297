@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto:wght@300&display=swap");
@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.bestSuitedForRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 75%;
  margin-top: 6rem;

  .title {
    font-family: "Inter", sans-serif;
    font-size: 2.6rem;
    font-weight: 800;
    color: #000000;
    margin-bottom: 5rem;
  }

  .titleDesc {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    margin-bottom: 4rem;
    text-align: center;
    line-height: 23px;
  }
  .cardSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .card {
    width: 29% !important;
    padding: 0px !important;
    border: 0px !important;

    .ant-card-body {
      padding: 0px !important;
    }

    img {
      border-radius: 10px;
    }

    .cardTitle {
      margin-top: 16px;
      font-family: "Inter", sans-serif;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 24px;
      color: #de5954;
    }
    .cardBody {
      font-family: "Inter", sans-serif;
      font-size: 1rem;
      font-style: normal;
      color: #020202;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 18px;
      letter-spacing: 0.01em;
      opacity: 0.87;
    }
  }

  .featureSection {
    display: flex;
    margin-top: 6rem;
    gap: 60px;
    width: 97%;
    .featureContent {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 47%;
      .featureHeader {
        display: flex;
        gap: 10px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .bestSuitedForRoot {
    .card {
      width: 100% !important;
    }
    .title {
      font-size: 34px !important;
    }
    .cardSection {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .featureSection {
      flex-direction: column;
      .featureHeader {
        .paragraph_text {
          font-size: 12px !important;
        }
      }
      .flexAlign-center {
        width: 100% !important;
      }
      .featureContent {
        width: 100% !important;
      }
    }
  }
}

.fontWeight-800 {
  font-weight: 700 !important;
}